import React, { ReactNode } from 'react'
import { ColorType, Flex, Text } from '@clear/design-system'

export type FeatureCardProps = {
  icon: ReactNode
  description: any
  descriptionColor?: ColorType
  borderWidth?: string
  borderStyle?: string
  borderColor?: string
}

export const FeatureCard = ({
  icon,
  description,
  borderWidth,
  borderStyle,
  borderColor,
}: FeatureCardProps) => {
  return (
    <Flex
      ml={[3, 7]}
      mr={[3, 7]}
      borderWidth={borderWidth}
      borderStyle={borderStyle}
      borderColor={borderColor}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex width="60px" height="60px" my={5}>
        {icon}
      </Flex>
      <Text as="h1" fontWeight="700" fontSize={[1, 3]} textAlign="center">
        {description}
      </Text>
    </Flex>
  )
}
