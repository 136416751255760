import React, { useEffect, useState, ReactNode } from 'react'

import router, { useRouter } from 'next/router'

import { Button, ColorType, Flex, Link, Text } from '@clear/design-system'
import PageContent from '@components/layout/PageContent'
import { PlanTypes, useEnrollmentContext } from '@components/EnrollmentContext'
import { Modal } from '@components/shared/Modal'

import { FeatureCard } from '@components/storybook/FeatureCard'

const hideEnrollmentlFlow =
  process.env.NEXT_PUBLIC_HIDE_ENROLLMENT_FLOW === 'true'

const HomePageBanner: React.FC = ({ children }) => {
  return (
    <Flex
      height={['auto', 'auto', '30rem']}
      flexDirection={['column', 'row']}
      justifyContent="flex-start"
    >
      <Flex
        height={['auto', '100%', '100%']}
        backgroundColor={'dhsBlue' as ColorType}
        width={['100%', '50%']}
        flexDirection="column"
        paddingLeft={['5%', '2%', '5%']}
        justifyContent="center"
        padding={['5%', '3%']}
      >
        <Text
          as="h1"
          fontSize={['1.75rem', '2.25rem', '3rem']}
          color="neutralsWhite"
          fontWeight="700"
          marginBottom="3%"
        >
          Enroll in TSA&nbsp;PreCheck<sup>&reg;</sup> Through CLEAR─
          <br />
          No Appointment Needed
        </Text>
        <Flex
          flexDirection={['column', 'row']}
          alignSelf="flex-start"
          justifyContent="flex-start"
          marginTop="2%"
          width={['100%', 'auto']}
        >
          {children}
        </Flex>
      </Flex>
      <Flex
        height={['15rem', 'auto']}
        width={['100%', '50%']}
        backgroundImage="url('/tsa-airport-til.svg')"
        backgroundPosition="100% 67%"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      />
    </Flex>
  )
}

const Section1: React.FC<{
  showApplyModal: () => void
}> = () => {
  return (
    <Flex
      flexDirection="column"
      pb={8}
      background="linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, #F8F8F8 47.68%)"
    >
      <Flex
        flexDirection="column"
        alignSelf="center"
        width={['full']}
        maxWidth="90rem"
        mt={6}
        px={[6, 6, 6, 6, 12]}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          mb={2}
          data-cy="feature-header"
        >
          <Text
            as="p"
            variant="medium"
            fontSize={[4, 5, 6]}
            color={'clearMidnightBlue' as ColorType}
            textAlign="center"
          >
            TSA PreCheck<sup>®</sup> Keeps You Moving Through Airport Security
          </Text>
        </Flex>
        <Flex
          flexDirection={['column', 'row']}
          mb={5}
          justifyContent="space-between"
        >
          <FeatureCard
            icon={<img src="/feature1.svg" alt="feature1" />}
            description="No need to remove shoes, laptops, 3-1-1 liquids, belts, or light jackets"
          />
          <Flex
            ml={[3, 7]}
            mr={[3, 7]}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Flex width="60px" height="60px" my={5}>
              <img src="/feature2.svg" alt="feature2" />
            </Flex>
            <Text
              as="p"
              variant="regular"
              fontWeight="700"
              fontSize={[1, 3]}
              textAlign="center"
            >
              Children 17 and under can join an adult with TSA&nbsp;PreCheck
              <sup>®</sup> when TSA&nbsp;PreCheck<sup>®</sup> appears on the
              child's boarding pass
            </Text>
          </Flex>
          <FeatureCard
            icon={<img src="/feature3.svg" alt="feature3" />}
            description="Available with 90+ airlines at 200+ airports nationwide"
          />
        </Flex>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="center"
          alignItems="center"
          marginTop="2%"
        >
          <Button
            text="APPLY FOR $77.95"
            id="homepage-apply-slowscroll-2"
            bg={'dhsBorderBlue' as ColorType}
            textProps={{ variant: 'regular', fontWeight: '600' }}
            mb={[5, 0]}
            mr={[0, 5]}
            width="300px"
            onClick={() => {
              router.push('/enroll/document-check')
            }}
          />
          <Button
            text="RENEW ONLINE FOR $68.95 "
            variant="secondary"
            id="homepage-renew-slowscroll-2"
            width="300px"
            textProps={{
              variant: 'regular',
              color: 'clearMidnightBlue' as ColorType,
              fontWeight: '600',
            }}
            onClick={() => {
              router.push('/renewal/eligibility/eligibility')
            }}
            mr={[0, 5]}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

const Section2: React.FC = () => {
  return (
    <Flex
      id="enroll"
      flexDirection="column"
      pb={8}
      backgroundColor={'dhsBlue' as ColorType}
    >
      <Flex
        alignItems="stretch"
        flexDirection="column"
        alignSelf="center"
        width={['full']}
        maxWidth="90rem"
        mt={8}
        px={[6, 6, 6, 6, 12]}
      >
        <Flex flexDirection="column" justifyContent="center" mb={6}>
          <Text
            as="p"
            variant="medium"
            fontSize={[4, 5, 6]}
            color="neutralsWhite"
            textAlign="center"
            fontWeight={2}
          >
            How To Apply
          </Text>
        </Flex>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="space-between"
          alignItems={['center', 'initial']}
          marginBottom="5%"
          color="neutralsWhite"
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={['90%', '30%']}
            textAlign="center"
            height="100%"
          >
            <img src="/how-to-apply1.svg" alt="how-to-apply1" />
            <Text as="p" fontSize={3} marginTop={5}>
              Start enrollment{' '}
              <Link color="neutralsWhite" href="/enroll" target="_blank">
                <span className="font-semibold underline">online</span>.
              </Link>
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={['90%', '30%']}
            marginTop={[5, 0]}
            height="100%"
            textAlign="center"
          >
            <img src="/how-to-apply2.svg" alt="how-to-apply2" />
            <Text as="p" fontSize={3} marginTop={5}>
              Head to any{' '}
              <Link target="_blank" href="/locations" color="neutralsWhite">
                <span className="font-semibold underline">
                  CLEAR enrollment location
                </span>
              </Link>{' '}
              to finish your application with our friendly Ambassadors in
              minutes.
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={['90%', '30%']}
            marginTop={[5, 0]}
            height="100%"
            textAlign="center"
          >
            <img src="/how-to-apply3.svg" alt="how-to-apply3" />
            <Text as="p" fontSize={3} marginTop={5}>
              You're all set! Once approved, receive your Known Traveler Number
              (KTN) in 3-5 days (some can take up to 60 days).
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Text
            as="p"
            fontSize={3}
            color="neutralsWhite"
            textAlign="center"
            mb={5}
            fontWeight="600"
          >
            Travelers will need to add their KTN to their airline reservations
            to receive the TSA&nbsp;PreCheck<sup>®</sup> indicator on their
            boarding pass.
          </Text>
        </Flex>

        <Flex
          flexDirection="row"
          width="100%"
          border="1px solid #fff"
          borderRadius="12px"
          padding={3}
          justifyContent="center"
          alignItems="center"
          color="neutralsWhite"
        >
          <img
            width="35px"
            src="/how-to-apply-banner-logo.svg"
            alt="how-to-apply-logo"
          />
          <Text as="p" variant="regular" fontSize={3} marginLeft={5}>
            Bring your U.S. passport or other accepted{' '}
            <Link
              href="/help#required-documents"
              color="neutralsWhite"
              target="_blank"
            >
              <span className="font-semibold underline">forms of ID</span>
            </Link>{' '}
            to finish your enrollment.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const Section3: React.FC<{
  setIsPlanBundled: (boolean: boolean) => void
  handleSelectPlan: (plan: PlanTypes) => void
}> = ({ setIsPlanBundled, handleSelectPlan }) => {
  return (
    <Flex width="100%" flexDirection="column" height="auot">
      <Flex
        width="100%"
        height="auto"
        backgroundImage="url('./clear-airport-terminal.svg')"
        backgroundPosition="50% 78%"
        backgroundSize="cover"
      >
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          padding={10}
          height="100%"
        >
          <img
            src="./tsa-clear-logo.svg"
            alt="tsa-clear-logo"
            width={407}
            height={67}
          />
          <Text
            as="h1"
            color={'clearMidnightBlue' as ColorType}
            fontSize={['1.75rem', '2.25rem', '3rem']}
            fontWeight="700"
            marginTop={5}
          >
            The Fastest Way to Your Gate
          </Text>
          <Text variant="regular" fontSize={3} fontWeight="700" marginTop={4}>
            CLEAR Plus is the perfect complement to TSA&nbsp;PreCheck
            <sup>®</sup>.<sup>**</sup>
          </Text>
          <Button
            key="card-1"
            id="get-the-bundle-1"
            text="GET THE BUNDLE"
            bg={'dhsBorderBlue' as ColorType}
            textProps={{ variant: 'medium' }}
            marginTop={8}
            onClick={() => {
              setIsPlanBundled(true)
              handleSelectPlan(PlanTypes.ENROLL)
            }}
            data-cy="get-the-bundle-enroll-btn"
          />
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        height="auto"
        justifyContent="center"
        alignItems="center"
        marginTop={5}
        marginBottom={5}
        width={['100%']}
      >
        <Flex
          width={['90%', '50%']}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={5}
        >
          <Flex
            backgroundImage="url(./tsa-pre-card-logo.svg)"
            width="45%"
            height={['29px', '30px', '45px']}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="contain"
          />
          <Flex
            backgroundImage="url(./ampersand.svg)"
            width={'10%'}
            height={['29px', '30px', '35px']}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
          />
          <Flex
            backgroundImage="url(./clear-plus-logo.svg)"
            width="45%"
            height={['29px', '30px', '45px']}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
          />
        </Flex>
        <Flex
          flexDirection="row"
          width={['100%', '60%']}
          justifyContent="center"
          textAlign="center"
          marginTop={2}
        >
          <Flex
            flexDirection="column"
            width="50%"
            justifyContent="space-between"
          >
            <Flex
              padding={5}
              margin={3}
              border="2px solid"
              borderRadius="18px"
              justifyContent="center"
              borderColor="dhsBorderGreen"
            >
              No need to remove shoes, laptops, 3-1-1 liquids, belts, or light
              jackets.
            </Flex>
            <Flex
              padding={5}
              margin={3}
              border="2px solid"
              borderRadius="18px"
              justifyContent="center"
              borderColor="dhsBorderGreen"
            >
              An even easier security experience.
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            width="50%"
            justifyContent="space-between"
          >
            <Flex
              padding={5}
              margin={3}
              border="2px solid"
              borderRadius="18px"
              justifyContent="center"
              borderColor="dhsBorderBlue"
            >
              Verify you are you at the CLEAR Pod.
            </Flex>
            <Flex
              padding={5}
              margin={3}
              border="2px solid"
              borderRadius="18px"
              justifyContent="center"
              borderColor="dhsBorderBlue"
            >
              CLEAR Ambassadors will bring you to the front of the TSA screening
              lane.
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginTop={10}
        >
          <Text
            as="p"
            variant="regular"
            textAlign="center"
            fontSize="1.3rem"
            width={['80%', '60%']}
          >
            Now when you sign up for CLEAR Plus, you can bundle it with
            TSA&nbsp;PreCheck<sup>®</sup> for the fastest way to your gate.
            <sup>*</sup>
          </Text>
          <Text
            as="h1"
            fontWeight="700"
            textAlign="center"
            color={'dhsBorderBlue' as ColorType}
            fontSize="3.5rem"
            margin={5}
          >
            $199
          </Text>
          <Button
            key="card-1"
            id="get-the-bundle-2"
            text="GET THE BUNDLE"
            bg={'dhsBorderBlue' as ColorType}
            textProps={{ variant: 'medium' }}
            onClick={() => {
              setIsPlanBundled(true)
              handleSelectPlan(PlanTypes.ENROLL)
            }}
            width={['60%', '35%']}
            data-cy="get-the-bundle-enroll-btn2"
          />
          <Text
            as="p"
            variant="regular"
            textAlign="center"
            marginTop={5}
            marginBottom="15rem"
            width={['80%', '60%']}
          >
            5 years of TSA&nbsp;PreCheck<sup>®</sup> for{' '}
            <Text variant="display">$77.95</Text> + 1 year of CLEAR Plus for{' '}
            <Text variant="display">$121.05</Text>{' '}
            <Text
              color="neutralsTextGray"
              style={{ textDecoration: 'line-through' }}
            >
              $199
            </Text>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const HomePage: React.FC = () => {
  const router = useRouter()
  const { setSelectedPlan, setAffiliate, setIsPlanBundled } =
    useEnrollmentContext()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalBody, setModalBody] = useState<string | ReactNode>(
    'Please note: We are accepting online renewals only. At this time, we are not accepting new enrollment applications or in-person renewals.'
  )

  const showApplyModal = () => {
    setModalBody(
      <Text variant="body" textAlign="center" color="neutralsTextGray">
        Please note: We are accepting online renewals only. At this time, we are
        not accepting new enrollment applications or in-person renewals.
      </Text>
    )
    setIsModalVisible(true)
  }
  const showModal = () => setIsModalVisible(true)
  const hideModal = () => setIsModalVisible(false)

  useEffect(() => {
    if (hideEnrollmentlFlow) showModal()
  }, [])

  const handleSelectPlan = async (plan: string) => {
    setSelectedPlan(plan)

    if (plan === PlanTypes.RENEW) {
      await router.push('/renewal/eligibility/eligibility')
    } else {
      await router.push(
        `/enroll/document-check${
          router.query.test === 'true' ? '?test=true' : ''
        }`
      )
    }
  }

  useEffect(() => {
    router.prefetch('enroll/document-check')
  }, [router])

  useEffect(() => {
    if (router.query.affiliate) {
      setAffiliate(router.query.affiliate as string)
    }
  }, [router, setAffiliate])

  return (
    <Flex flexDirection="column" id="HomePageWrapper">
      <HomePageBanner>
        <Flex
          justifyContent="center"
          alignContent="flex-start"
          width={['100%', '20rem', 'auto']}
          flexDirection={['column', 'row']}
        >
          <Button
            key="card-1"
            id="homepage-apply-slowscroll-1"
            text="APPLY"
            variant="white"
            textProps={{
              fontWeight: '600',
              color: 'clearMidnightBlue' as ColorType,
            }}
            mr={[0, 5]}
            mb={[5, 0]}
            onClick={() => {
              handleSelectPlan(PlanTypes.ENROLL)
            }}
            data-cy="home-banner-enroll-btn"
          />
          <Button
            text="RENEW"
            variant="secondaryWhite"
            id="homepage-renew-slowscroll-1"
            textProps={{ fontWeight: '600' }}
            backgroundColor="neutralsWhite"
            onClick={() => {
              handleSelectPlan(PlanTypes.RENEW)
            }}
            data-cy="home-banner-renew-btn"
          />
        </Flex>
      </HomePageBanner>
      <PageContent width="full" alignSelf="center" m={0} p={0}>
        <Section1 showApplyModal={showApplyModal} />
        <Section2 />
        <Section3
          handleSelectPlan={handleSelectPlan}
          setIsPlanBundled={setIsPlanBundled}
        />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mb={8}
        >
          <Text
            width={['90%', '70%']}
            fontSize={0}
            textAlign="center"
            color="neutralsTextGray"
          >
            Terms apply. *Offer available to non-CLEAR Plus Members. $77.95 will
            be charged in-person for your TSA&nbsp;PreCheck<sup>®</sup>{' '}
            application. $199 will be charged for a 1-year CLEAR Plus
            Membership. CLEAR Plus Membership automatically renews annually for
            $199. Must apply for both TSA PreCheck<sup>®</sup> through CLEAR and
            CLEAR Plus within 7 days using the same email and must opt-in for
            the bundle. Rebate of $77.95 will be issued when you complete both
            your CLEAR Plus enrollment and TSA PreCheck<sup>®</sup> application.
            CLEAR Plus is currently only available to U.S. citizens and legal
            permanent residents 18 and older with a valid government-issued
            photo ID.
          </Text>
          <Text
            width={['90%', '60%']}
            fontSize={0}
            textAlign="center"
            color="neutralsTextGray"
          >
            <sup>**</sup>This service is not associated with the
            TSA&nbsp;PreCheck<sup>®</sup>
            Application Program.
          </Text>
        </Flex>
        <Modal
          showModal={isModalVisible}
          setShowModal={setIsModalVisible}
          body={modalBody}
          canClose={true}
          successButtonText="Ok"
          successClick={() => hideModal()}
        />
      </PageContent>
    </Flex>
  )
}

export default HomePage
